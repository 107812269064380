import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

import "../styles/Header.css"

import reverseArray from '../utilities/reverseArray';

const links = [

    // {
    //     display: 'Home',
    //     link: '/'
    // },
    {
        display: 'CAD / Surveying',
        link: '/CAD'
    },
    {
        display: "SHOWS & SALES",
        link: "/ShowsAndSales"
    },
    {
        display: 'Interior Design',
        link: '/InteriorDesign'
    },
    {
        display: 'Art Gallery',
         link: '/Artwork'
    },
    {
        display: 'Photography',
        link: '/Photography'
    },
    {
        display: 'Contact',
         link: '/Contact'
    },
    

]



class Header extends Component {


    state = {

        navOpen: false,

    }

    toHome = () => {
        this.setState({ navOpen: false });
        this.props.history.push("/");
    }

    toLink = (link) => {
        this.setState({ navOpen: false });
        this.props.history.push(link);
    }

    toggleNav = async () => {
        await this.setState({ navOpen: !this.state.navOpen });
    }





    render = () => {

        const pathRoot = '/' + window.location.pathname.split('/')[1];

        const reversedLinks = reverseArray(links);
        

        return (
            <div>
                <div className="Header">


                    {/* Logo */}
                    {/* <div className='LogoText' onClick={this.toHome}>AYISEN</div> */}

                    {/* Header logo */}
                    <img className="HeaderLogo" src = "/resources/LOGO.png"  onClick={() => this.props.history.push('/')}/>
                    
                    {reversedLinks.map( (linkData) => {
                        return <p className={"HeaderOption" + (pathRoot === linkData.link ? ' HeaderActive' : '')} onClick={()=>this.toLink(linkData.link)} >{linkData.display}</p>
                    })}
                    
                    {/* Nav Opener */}
                    <img className='headerOpener' src='/resources/Icons/nav.png' alt='Nav'  onClick={this.toggleNav}/>

                    {/* Side Nav */}
                    <div className={'navBar' + (this.state.navOpen ? ' navBarOpen' : '')}>

                        {links.map( (linkData, idx) => {
                            return <div className={'navOpt' + (pathRoot === linkData.link ? ' navOptActive' : '')}
                                        onClick={()=>this.toLink(linkData.link)}
                                    >
                                {linkData.display}

                            </div>
                        } )}

                    </div>

                    {this.state.navOpen && 
                        <div className='navBarExiter' onClick={()=>this.setState({navOpen: false})}/>
                    }
                    
                    
                </div>


            </div>
        )
    }

}


export default withRouter(Header);