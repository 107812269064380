import React, { Component } from 'react';

import Gallery from '../Helpers/Gallery';
import GalleryDescription from '../Helpers/GalleryDescription';

import '../../styles/GalleryPage.css';

import {interiorDesign, showsAndSales} from '../../constants/displayedItems';


export default class ShowsAndSalesGallery extends Component {



    render = () => {

        return (
        
            <div className='galleryPage fadeQuick'> 

                <div style = {{height: 40}}/>


                {/* Description */}

                <GalleryDescription
                    unpadTop = {true}
                >

                </GalleryDescription>


                {/* Interior Design Gallery */}
                <Gallery
                    items = { showsAndSales }
                    animate = {true}
                />
                

            </div>

            


        )
        
    }


}