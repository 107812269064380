import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Components 
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';

import './styles/root.css';
import ArtGallery from './components/Pages/Artwork';
import InteriorDesignGallery from './components/Pages/InteriorDesign';
import CADGallery from './components/Pages/CAD';
import PhotoGallery from './components/Pages/Photography';
import Contact from './components/Pages/Contact';
import ShowsAndSalesGallery from './components/Pages/ShowsAndSales';

function App() {

  return (
    <div className='root'>

      <Router>

      {/* Header / Footer */}
      <Header />
      <Footer />
      <div style={{height: '60px'}} />

  
      <Switch>

        {/* CAD */}
        <Route exact path='/CAD' 
          render={({...props}) => <CADGallery />}
        />

        {/* Art */}
        <Route exact path='/Artwork' 
          render={({...props}) => <ArtGallery />}
        />

        {/* Interior Design */}
        <Route exact path='/InteriorDesign' 
          render={({...props}) => <InteriorDesignGallery />}
        />

        {/* Interior Design */}
        <Route exact path='/ShowsAndSales' 
          render={({...props}) => <ShowsAndSalesGallery />}
        />

        {/* Photography */}
        <Route exact path='/Photography' 
          render={({...props}) => <PhotoGallery />}
        />

        {/* Contact */}
        <Route exact path='/Contact' 
          render={({...props}) => <Contact />}
        />

        {/* Home */}
        <Route exact path='/' 
          render={({...props}) => <HomePage />}
        />
        

      </Switch>
      </Router>
      
    </div>
  );
}

export default App;
